import React, { memo, useCallback } from 'react';
import { Image } from 'react-native';
import { Box, VStack, Text, Select, CheckIcon, FlatList } from 'native-base';
import { IImageItem, IRejectionMapping } from 'src/models/UploadImages';

interface ImageItemProps {
  item: IImageItem;
  currentLabelKey: string;
  rejectionMapping: IRejectionMapping;
  onLabelChange: (imagePath: string, labelKey: string) => void;
  isExistingDataset?: boolean;
}

const ImageItem: React.FC<ImageItemProps> = memo(
  ({ item, currentLabelKey, rejectionMapping, onLabelChange, isExistingDataset }) => {
    const imagePathDisplay = item.imagePath?.split('/').pop() ?? 'Unknown';
    const options = { ...rejectionMapping };
    if (currentLabelKey && !options[currentLabelKey]) {
      options['customLabel'] = 'Custom Label';
    }

    return (
      <Box
        flex={1}
        m={2}
        borderWidth="1"
        borderColor="coolGray.200"
        borderRadius="md"
        bg="white"
        overflow="hidden"
      >
        <VStack space={2}>
          <Box width="100%" height={200} justifyContent="center" alignItems="center">
            <Image
              source={{ uri: isExistingDataset ? item.imageUrl : `${item.imageUrl}?timestamp=${new Date().getTime()}` }}
              style={{
                width: '100%',
                height: '100%',
                resizeMode: 'contain',
              }}
            />
          </Box>
          <Text fontSize="sm" bold numberOfLines={1}>
            {imagePathDisplay}
          </Text>
          <Select
            selectedValue={currentLabelKey}
            minWidth="100%"
            placeholder="Select Label"
            _selectedItem={{
              bg: 'cyan.600',
              endIcon: <CheckIcon size="5" />,
            }}
            onValueChange={(value) => onLabelChange(item.imagePath, value)}
          >
            {Object.entries(options).map(([key, value]) => (
              <Select.Item key={key} label={value} value={key} />
            ))}
          </Select>
        </VStack>
      </Box>
    );
  }
);

interface ImageTableProps {
  images: IImageItem[];
  changedLabels: { [imagePath: string]: string };
  rejectionMapping: IRejectionMapping;
  onLabelChange: (imagePath: string, labelKey: string) => void;
  isExistingDataset?: boolean;
}

const ImageTable: React.FC<ImageTableProps> = memo(
  ({ images, changedLabels, rejectionMapping, onLabelChange, isExistingDataset }) => {
    const renderImageItem = useCallback(
      ({ item }: { item: IImageItem }) => {
        const changedLabelKey = changedLabels[item.imagePath];
        const originalLabelKey = rejectionMapping[item.label]
          ? item.label
          : getKeyByValue(rejectionMapping, item.label);
        const currentLabelKey = changedLabelKey || originalLabelKey || '';
        return (
          <ImageItem
            item={item}
            currentLabelKey={currentLabelKey}
            rejectionMapping={rejectionMapping}
            onLabelChange={onLabelChange}
            isExistingDataset={isExistingDataset}
          />
        );
      },
      [changedLabels, rejectionMapping, onLabelChange]
    );

    return (
      <FlatList
        data={images}
        renderItem={renderImageItem}
        keyExtractor={(item) => item.imagePath || item.id}
        ListEmptyComponent={() => <Text>No images to display.</Text>}
        extraData={changedLabels}
        numColumns={2}
        contentContainerStyle={{ paddingBottom: 20 }}
      />
    );
  }
);

export default ImageTable;

export const getKeyByValue = (mapping: { [key: string]: string }, value: string) => {
  return Object.keys(mapping).find((key) => mapping[key] === value);
};