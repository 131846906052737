import React from 'react';
import { IStore } from "../../../redux/reducers";
import { useSelector } from "react-redux";
import { View, Text } from 'react-native';
import { IOrgJobListItem, JobStatus } from '../../../models';
import { Badge, HStack, Menu, Pressable, Box } from 'native-base';

import { getDisplayableJobId, getBadgeColor, getStatusMessage, getFormattedDate } from '../../../utils/jobUtils'
import { listStyle } from 'src/styles/listStyle';
import { cardStyle } from 'src/styles/cardStyle';
import TextWithIcon from '../../TextWithIcon';
import MaterialIconByName from '../../MaterialIconByName';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    label: {
        fontSize: 14,
        fontWeight: 'bold',
        color: 'black',
    },
});

interface OrgJobHistoryListItemProps {
    item: IOrgJobListItem;
    itemCallback: (item: IOrgJobListItem) => void;
    downloadImageCallback: (jobId: string) => void;
    retryJobCallback: (item: IOrgJobListItem) => void;
    deleteJobCallback: (jobId: string) => void; 
}

export default function OrgJobHistoryListItem(props: OrgJobHistoryListItemProps) {
    const grainTypes = useSelector((store: IStore) => store.user.userProfile.response?.grains);
    const currentStack = useSelector((store: IStore) => store.app.currentStack);
    const getGrainName = (grainId: string): string => {
        return grainTypes?.filter(grain => grain.grainId === grainId)[0]?.name || "";
    }

    return (
        <Pressable
            onPress={() => {
                props.itemCallback(props.item)
            }}
            style={cardStyle.normalCard}
            rounded="8"
            overflow="hidden"
            borderWidth="1"
            borderColor="coolGray.300"
            shadow="3"
            bg="coolGray.100" p="4">
            <Box>
                <View style={listStyle.cardSection}>
                    <View style={{ width: "45%" }}>
                        <Text style={listStyle.title}>Job ID: {getDisplayableJobId(props.item.jobId)}</Text>
                        <HStack style={{
                            marginTop: 8,
                            flexWrap: 'wrap',
                        }} space={2}>
                            <TextWithIcon icon="barley" text={getGrainName(props.item.grainId)} />
                            <TextWithIcon icon="clock-time-nine" text={getFormattedDate(props.item.updatedAt)} />
                            <TextWithIcon icon="seed" text={
                                `Variety: ${props.item.variety && props.item.variety !== "Default"
                                    ? props.item.variety
                                    : "N/A"}`
                            } />
                        </HStack>
                    </View>
                    <View style={{ justifyContent: 'center' }}>
                        <View style={{ flexDirection: "row" }}>
                            {currentStack === "mlAdmin" &&
                                props.item.jobStatus === JobStatus.JOB_COMPLETED &&
                                props.item.mlLabelingStatus === "USED_FOR_LABELING" && (
                                    <Badge
                                        variant='solid'
                                        colorScheme="purple"
                                        style={{ marginRight: 8 }}
                                    >
                                        LABELED
                                    </Badge>
                                )
                            }
                            <Badge variant='solid' colorScheme={getBadgeColor(props.item.jobStatus)}>{getStatusMessage(props.item.jobStatus)}</Badge>
                            <View style={{ justifyContent: 'center', marginLeft: 8 }}>
                                <Menu trigger={triggerProps => {
                                    return <Pressable accessibilityLabel="More options" {...triggerProps}>
                                        <MaterialIconByName name="dots-vertical" />
                                    </Pressable>;
                                }}>
                                    {
                                        <Menu.Item onPress={() => {
                                            props.retryJobCallback(props.item)
                                        }}>Retry</Menu.Item>
                                    }
                                    <Menu.Item onPress={() => {
                                        props.downloadImageCallback(props.item.jobId)
                                    }}>Download image</Menu.Item>
                                    <Menu.Item onPress={() => {
                                        props.deleteJobCallback(props.item.jobId)
                                    }}>Delete</Menu.Item>
                                </Menu>
                            </View>
                        </View>
                    </View>
                </View>
            </Box>
        </Pressable>
    );
};