import { S3_BUCKET_URL } from 'src/constants';
import { ICsvImageData, IImageItem } from 'src/models/UploadImages';


  const parseCSV = (csvText: string): ICsvImageData[] => {
    const lines = csvText.split('\n');
    const headers = lines[0].split(',');
  
    return lines.slice(1).map((line) => {
      const values = line.split(',');
      return headers.reduce((obj, header, index) => {
        obj[header.trim()] = values[index];
        return obj;
      }, {} as ICsvImageData);
    });
  };
  

  const fetchCSVData = async (csvUrl: string): Promise<ICsvImageData[]> => {
    try {
      const response = await fetch(csvUrl);
      const csvText = await response.text();
      return parseCSV(csvText);
    } catch (error) {
      throw error;
    }
  };

export const processImages = async (isJob : boolean , jobData : any , jobDetails : any, images : IImageItem[], jobId : string, datasetId : string, isExistingDataset : boolean, isDataFetched : boolean) => {
    let newImages:IImageItem[] = [];
    if (!isExistingDataset && isJob && isDataFetched) {
      let extractedJobIdentifier = jobId.replace('dataset_jobId_', '');
      const csvUrl = `${S3_BUCKET_URL()}/output/${extractedJobIdentifier}/indiGrainLabelsIndiGrains.csv`;
      const csvData = await fetchCSVData(csvUrl);
      newImages = await processImageData(csvData, jobId, true);
    } else if (isExistingDataset && isJob) {
      newImages = await processImageData(images, jobId);
    } else if (!isJob) {
      newImages = await processImageData(images, datasetId);
    }

    return newImages;
};

const processImageData = async (sourceImages: ICsvImageData[] | IImageItem[], sourceId: string, isCSVData = false) => {
  return Promise.all(
    sourceImages
      .filter((img) => {
        const imagePath = isCSVData ? img.ImagePath : img.imagePath;
        return imagePath && imagePath.trim() !== '';
      })
      .map(async (img) => {
        const imagePath = isCSVData ? img.ImagePath : img.imagePath;
        const label = isCSVData ? img.label : img.label || 'Good Grains';
        return {
          imagePath: imagePath,
          label: label,
          imageUrl: isCSVData
            ? `${S3_BUCKET_URL()}/${img.ImagePath}`
            : img.imageUrl ||
              `${S3_BUCKET_URL()}/${img.imagePath}`,
        };
      })
  );
};

