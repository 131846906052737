
import * as React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { getText } from 'src/utils/i18n';
import DatasetListingPage from '../DatasetListing';
import ImageUploadPage from 'src/pages/MlPages/ImageUploadPage';
import CombinedLabelingPage from 'src/pages/MlPages/CombinedLabelingPage';
import UserJobHistoryList from 'src/components/list/UserJobHistoryList';
import CreateTrainingJobPage from 'src/pages/MlPages/trainingJobPage';
import { useSelector } from 'react-redux';
import { IStore } from 'src/redux/reducers';
const Tab = createMaterialTopTabNavigator();
export default function HomeTabs({ navigation }) {
    const canCreateTrainingJob = useSelector(
        (state: IStore) =>
            state.user.userProfile.response?.settings?.create_ml_training_job_allowed
    );
    const canCreateTestingJob = useSelector((state: IStore) => state.user.userProfile.response?.settings?.create_ml_testing_job_allowed);
    return (
        <Tab.Navigator
            screenOptions={() => ({
                tabBarActiveTintColor: 'black',
                tabBarInactiveTintColor: 'gray',
                tabBarLabelStyle: { fontSize: 8 },
            })}>
            <Tab.Screen
                name="DatasetListingPage"
                component={DatasetListingPage} options={{
                    tabBarLabel: getText('datasetListing'),
                    tabBarIcon: ({ color }) => (
                        <MaterialCommunityIcons name="format-list-bulleted" color={color} size={24} />
                    ),
                }} />
            <Tab.Screen name="ImageUploadPage"
                component={ImageUploadPage}
                options={{
                    tabBarLabel: getText('uploadImage'),
                    tabBarIcon: ({ color }) => (
                        <MaterialCommunityIcons name="upload" color={color} size={24} />
                    ),
                }} />
            <Tab.Screen
                name="CombinedLabelingPage"
                component={CombinedLabelingPage} options={{
                    tabBarLabel: getText('CombinedLabelingPage'),
                    tabBarIcon: ({ color }) => (
                        <MaterialCommunityIcons name="label-variant" color={color} size={24} />
                    ),
                }} />
            {canCreateTrainingJob && (
                <Tab.Screen
                    name="CreateTrainingJobPage"
                    component={CreateTrainingJobPage} options={{
                        tabBarLabel: getText('trainModel'),
                        tabBarIcon: ({ color }) => (
                            <MaterialCommunityIcons name="update" color={color} size={24} />
                        ),
                    }} />
            )}
            {canCreateTestingJob && (
                <Tab.Screen
                    name="TestingJobPage"
                    component={CreateTrainingJobPage} options={{
                        tabBarLabel: getText('testModel'),
                        tabBarIcon: ({ color }) => (
                            <MaterialCommunityIcons name="text-box-check-outline" color={color} size={24} />
                        ),
                    }} />
            )}
            <Tab.Screen
                name="JobListingPage"
                options={{
                    tabBarLabel: getText('history'),
                    tabBarIcon: ({ color }) => (
                        <MaterialCommunityIcons name="clipboard-text-clock" color={color} size={24} />
                    ),
                }}
            >
                {props => <UserJobHistoryList {...props} navigation={navigation} pageType='' />}

            </Tab.Screen>
        </Tab.Navigator>
    );
}