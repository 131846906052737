import React, { useCallback, useState, useEffect } from 'react';
import {
  FlatList,
  Text,
  Spinner,
  VStack,
  HStack,
  Divider,
  Center,
  Input,
  Button,
  Box,
  Modal,
} from 'native-base';
import { useSelector, useDispatch } from 'react-redux';
import { useFocusEffect } from '@react-navigation/native';

import * as UploadAction from 'src/redux/actions/dataset';
import { fetchRejectionMapping, deleteDataset } from 'src/redux/actions/dataset'; 
import * as JobActions from 'src/redux/actions/jobAction';
import * as GrainTypeAction from 'src/redux/actions/grainTypeAction';
import { clearDataset } from 'src/redux/actions/dataset';
import { IStore } from 'src/redux/reducers';
import { getGrainName } from 'src/utils/utils';

import DatasetListItem from 'src/components/listItem/DatasetListItem';

const PAGE_SIZE = 10;

const DatasetListingPage = ({ navigation, route }) => {
  const dispatch = useDispatch();

  const { datasets, loading, nextKey, rejectionMapping } = useSelector(
    (state: IStore) => state.dataset
  );
  const canDeleteDataset = useSelector((state: IStore) => state.user.userProfile.response?.settings?.delete_ml_dataset_allowed);
  const orgJobs = useSelector((store: IStore) => store.job.orgJobs.jobs);
  const orgId = useSelector((store: IStore) => store.user.userProfile.response?.org.orgId) ?? '';
  const grainTypes = useSelector((store: IStore) => store.user.userProfile.response?.grains);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredDatasets, setFilteredDatasets] = useState([]);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [expandedDatasetId, setExpandedDatasetId] = useState<string | null>(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedDatasetToDelete, setSelectedDatasetToDelete] = useState<string | null>(null);
  const labelCountsMap = useSelector((state: IStore) => state.dataset.labelCountsMap);

  useFocusEffect(
    useCallback(() => {
      setExpandedDatasetId(null);
      dispatch(UploadAction.setUploadProgress(0));
      dispatch(UploadAction.uploadComplete(false));
    }, [dispatch])
  );
  useEffect(() => {
    if (route.params?.refresh) {
      // Clear existing datasets and reload fresh data
      dispatch(clearDataset());
      dispatch(UploadAction.fetchDatasetInfo.request({ 
        limit: PAGE_SIZE, 
        lastKey: null 
      }));
      // Immediately clear the refresh param after handling
      navigation.setParams({ refresh: undefined });
    }
  }, [route.params?.refresh, dispatch, navigation]); // Add dependencies
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsInitialLoading(false);
    }, 1500);
    return () => clearTimeout(timeoutId);
  }, []);

  useFocusEffect(
    useCallback(() => {
      dispatch(UploadAction.setIsExistingDataset(true));

      if (!orgJobs) {
        dispatch(JobActions.orgJobs.request({ orgId }));
      }

    }, [orgJobs, orgId, navigation, route.params?.refresh])
  );

  const filterGrainId = useSelector((store: IStore) => store.app.grainIdFilter) || 'all';

  useEffect(() => {
    const filtered = datasets
      .filter(filterBySearchTerm)
      .filter(filterByGrainId)
      .sort(sortByUpdatedAt);
    setFilteredDatasets(filtered);
  }, [datasets, searchTerm, filterGrainId]);

  const filterBySearchTerm = (dataset) => {
    const lowerSearch = searchTerm.toLowerCase();
    const datasetID = dataset.datasetID ? dataset.datasetID.toLowerCase() : '';
    const username = dataset.username ? dataset.username.toLowerCase() : '';
    const grainName = (getGrainName(grainTypes, dataset.grainId) || "").toLowerCase();
    return (  
      datasetID.includes(lowerSearch) ||
      username.includes(lowerSearch) ||
      grainName.includes(lowerSearch)
    );
  };

  const filterByGrainId = (dataset) => {
    return filterGrainId === 'all' || dataset.grainId === filterGrainId;
  };

  const sortByUpdatedAt = (a, b) => b.updatedAt - a.updatedAt;


  const loadMoreDatasets = useCallback(async () => {
    if (isFetchingMore || !nextKey) return;
    setIsFetchingMore(true);
    try {
      dispatch(UploadAction.fetchDatasetInfo.request({ limit: PAGE_SIZE, lastKey: nextKey }));
    } catch (error) {
      console.error('Error loading more datasets:', error);
    } finally {
      setIsFetchingMore(false);
    }
  }, [dispatch, isFetchingMore, nextKey]);

  const handleDatasetPress = useCallback(
    async (item) => {
      const isCurrentlyExpanded = expandedDatasetId === item.datasetID;
      const newExpandedId = isCurrentlyExpanded ? null : item.datasetID;
      setExpandedDatasetId(newExpandedId);
      
      if (newExpandedId) {
        dispatch(fetchRejectionMapping.request({ grainId: item.grainId }));
        dispatch(UploadAction.fetchLabelCounts.request({ datasetId: item.datasetID }));
      }
    },
    [expandedDatasetId, dispatch]
  );
  const handleLabelDataset = useCallback(
    async (datasetId: string) => {
      dispatch(UploadAction.setIsExistingDataset(true));
      const selectedDataset = datasets.find((dataset) => dataset.datasetID === datasetId);

      if (selectedDataset) {
        dispatch(GrainTypeAction.setGrainId(selectedDataset.grainId));
        let extractedJobIdentifier = datasetId;
        if (datasetId.startsWith('dataset_jobId_')) {
          extractedJobIdentifier = datasetId.replace('dataset_jobId_', '');
          const selectedJobStatus = orgJobs?.Items.find((job) => job.jobId === extractedJobIdentifier);
          if (selectedJobStatus) {
            dispatch(JobActions.jobStatus.success(selectedJobStatus));
            dispatch(JobActions.setJobId(datasetId));
            dispatch(JobActions.pollJobStatus.request(extractedJobIdentifier));
          }
          dispatch(UploadAction.setIsJob(true));
          dispatch(UploadAction.setId(datasetId));
          navigation.navigate('CombinedLabelingPage');
        } else {
          dispatch(UploadAction.setDatasetId(datasetId));
          dispatch(UploadAction.setIsJob(false));
          dispatch(UploadAction.setId(datasetId));
          navigation.navigate('CombinedLabelingPage');
        }
      }
    },
    [dispatch, navigation, orgJobs, datasets]
  );

  const handleUploadImages = useCallback(
    (datasetId: string) => {
      const selectedDataset = datasets.find(d => d.datasetID === datasetId);
      if (selectedDataset) {
        dispatch(GrainTypeAction.setGrainId(selectedDataset.grainId));
        dispatch(UploadAction.setIsExistingDataset(true));
        dispatch(UploadAction.setDatasetId(datasetId));
        navigation.navigate('ImageUploadPage');
      }
    },
    [dispatch, navigation, datasets]
  );

  const handleDeleteDataset = useCallback(
    (datasetId: string) => {
      setSelectedDatasetToDelete(datasetId);
      setDeleteModalVisible(true);
    },
    []
  );

  // Confirm deletion: dispatch the delete action
  const confirmDelete = () => {
    if (selectedDatasetToDelete) {
      dispatch(deleteDataset.request(selectedDatasetToDelete));
    }
    setDeleteModalVisible(false);
    setSelectedDatasetToDelete(null);
  };

  // Cancel deletion: simply close the modal
  const cancelDelete = () => {
    setDeleteModalVisible(false);
    setSelectedDatasetToDelete(null);
  };

  const renderItem = useCallback(
    ({ item, index }) => {
      const isExpanded = expandedDatasetId === item.datasetID;
      const labelCountsEntry = labelCountsMap[item.datasetID] || { counts: {} };
      const labelCounts = labelCountsEntry.counts;

      return (
        <Box borderRadius="md" s  hadow={2} p={4} mb={4} bg="white">
          <DatasetListItem
            item={item}
            rowNumber={index + 1}
            onDatasetPress={handleDatasetPress}
            onLabelDataset={handleLabelDataset}
            onUploadImages={handleUploadImages}
            onDeleteDataset={canDeleteDataset ? handleDeleteDataset : null}
            getGrainName={(grainId) => getGrainName(grainTypes, grainId)}
          />
          {isExpanded && Object.keys(labelCounts).length > 0 && (
            <HStack mt={2} space={3} flexWrap="wrap" alignItems="center">
              {Object.entries(labelCounts).map(([labelKey, count]) => {
                        const displayLabel = rejectionMapping[labelKey] || labelKey;
                        return (
                          <Box 
                            key={labelKey}
                            borderWidth={1}
                            borderColor="coolGray.200"
                            borderRadius="md"
                            p={2}
                            mr={2}
                            mb={2}>
                            <Text>{displayLabel}</Text>
                            <Text>{count}</Text>
                          </Box>
                        );
                      })}
            </HStack>
          )}
        </Box>
      );
    },
    [
      expandedDatasetId,
      labelCountsMap,
      handleDatasetPress,
      handleLabelDataset,
      handleUploadImages,
      handleDeleteDataset,
      grainTypes,
    ]
  );

  if (isInitialLoading) {
    return (
      <Center flex={1} px="3">
        <Spinner accessibilityLabel="Loading datasets" />
      </Center>
    );
  }

  return (
    <VStack space={4} p={5} flex={1}>
      <HStack space={2} justifyContent="space-between" alignItems="center">
          <Text fontSize="xl" fontWeight="bold">
            Available Datasets
          </Text>
          <Button
            onPress={() => {
              dispatch(clearDataset());
              dispatch(UploadAction.fetchDatasetInfo.request({ limit: PAGE_SIZE, lastKey: null }));
            }}
          >
            Refresh Datasets
          </Button>
        </HStack>
      <HStack space={2}>
        <Input
          flex={1}
          placeholder="Search by Dataset ID, Username, or Grain Name"
          value={searchTerm}
          onChangeText={setSearchTerm}
        />
      </HStack>

      <Divider />

      {filteredDatasets.length > 0 ? (
        <FlatList
          data={filteredDatasets}
          renderItem={renderItem}
          keyExtractor={(item) => item.datasetID}
          ListFooterComponent={
            nextKey ? (
              <Center py={4}>
                <Button onPress={loadMoreDatasets}>Load More</Button>
              </Center>
            ) : null
          }
          refreshing={loading}
          onRefresh={() => {
            dispatch(clearDataset());
            dispatch(UploadAction.fetchDatasetInfo.request({ limit: PAGE_SIZE, lastKey: null }));
          }}
        />
      ) : (
        <Center flex={1}>
          <Text>loading..</Text>
        </Center>
      )}
      <Modal isOpen={deleteModalVisible} onClose={cancelDelete}>
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>Confirm Delete</Modal.Header>
          <Modal.Body>
            <Text>
              Are you sure you want to delete {selectedDatasetToDelete}?
            </Text>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button variant="ghost" onPress={cancelDelete}>
                Cancel
              </Button>
              <Button colorScheme="danger" onPress={confirmDelete}>
                Delete
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </VStack>
  );
};

export default DatasetListingPage;