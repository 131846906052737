import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../redux/reducers";
import * as OrgActions from '../../../redux/actions/orgAction';
import { View, RefreshControl, ListRenderItem } from 'react-native';
import { containerStyle } from 'src/styles/containerStyle';
import { ITruck } from 'src/models/Center';
import CenterTruckListItem from '../../listItem/CenterTruckListItem';
import ProgressBar from '../../ProgressBar';
import { Box, Text, FlatList } from 'native-base';

export default function CentersWithTrucksListComponent({ navigation }) {
  const [refreshing, setRefreshing] = React.useState(false);

  const isFetchingCenters = useSelector((store: IStore) => store.org?.centerWithTrucks?.isFetching) || false;
  const centersWithTrucks = useSelector((store: IStore) => store.org?.centerWithTrucks?.center?.trucks) || [];

  const orgId = useSelector((store: IStore) => store.user.userProfile.response?.org.orgId);
  const centerIdFilter = useSelector((store: IStore) => store.app.centerIdFilter);
  const districtFilter = useSelector((store: IStore) => store.app.districtFilter) || "all";
  const stateFilter = useSelector((store: IStore) => store.app.stateFilter) || "all";
  const grainIdFilter = useSelector((store: IStore) => store.app.grainIdFilter) || "all";
  const dateRange = useSelector((store: IStore) => store.app.dateRange);
  const searchBy = useSelector((store: IStore) => store.app.searchQuery?.type);
  const searchQuery = useSelector((store: IStore) => store.app.searchQuery?.query);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchCenterWithTrucks();
  }, [orgId,
    centerIdFilter,
    districtFilter,
    stateFilter,
    grainIdFilter,
    dateRange,
    searchQuery,
    searchBy]);

  const fetchCenterWithTrucks = async () => {
    if (orgId && centerIdFilter) {
      dispatch(OrgActions.getCenterTrucks.request({
        orgId: orgId,
        centerId: centerIdFilter || undefined,
        grainId: grainIdFilter || undefined,
        farmerCode: searchBy === 'farmerCode' && searchQuery
          ? searchQuery : undefined,
        state: stateFilter || undefined,
        district: districtFilter || undefined,
        startDate: dateRange?.from || undefined,
        endDate: dateRange?.to || undefined,
        stats: true
      }));
    }
  };

  const renderItem: ListRenderItem<ITruck> = ({ item }) => (
    <CenterTruckListItem item={item} />
  );

  return (
    <View style={containerStyle.listContainer}>
      <FlatList
        data={centersWithTrucks}
        renderItem={renderItem}
        keyExtractor={item => item.truckID}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={fetchCenterWithTrucks}
          />
        }
        ListEmptyComponent={
          isFetchingCenters ? <View style={{
            flex: 1,
            padding: 48,
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <ProgressBar
              progressText="Loading..."
              isVisible={true}
              withoutOverlay={true} />
          </View> : <Box style={{
            flex: 1,
            padding: 48,
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Text>No trucks found for the selected filters</Text>
          </Box>
        }
      />
    </View>
  );
}
