import React, { useState } from 'react';
import { Modal, Center, Box, ScrollView, Text, Select, Input, Switch, HStack, Button, VStack } from 'native-base';
import * as TrainingActions from 'src/redux/actions/trainingAction';

interface UpdateJobModalProps {
  job: any;
  onClose: () => void;
  onCopyJobId: (jobID: string) => void;
  dispatch: any;
}

const UpdateJobModal: React.FC<UpdateJobModalProps> = ({
  job,
  onClose,
  onCopyJobId,
  dispatch
}) => {
  const [updateSettings, setUpdateSettings] = useState({
    trainingJobID: job.trainingJobID,
    modelName: job.updatedAttributes?.modelName || job.modelName || 'resnet-18',
    checkpointPath: job.updatedAttributes?.checkpointPath || job.checkpointPath || '',
    isErosionEnabled: job.updatedAttributes?.trainingConfig?.is_erosion_enabled ?? job.trainingConfig?.is_erosion_enabled ?? false,
    isMaskingEnabled: job.updatedAttributes?.trainingConfig?.is_masking_enabled ?? job.trainingConfig?.is_masking_enabled ?? false,
    useEdgeDetection: job.updatedAttributes?.trainingConfig?.use_edge_detection ?? job.trainingConfig?.use_edge_detection ?? false,
    isUpdating: false
  });

  const handleSettingsChange = (field, value) => {
    setUpdateSettings(prev => ({ ...prev, [field]: value }));
  };

  const handleSaveUpdateJobConfig = async () => {
    if (job.trainingJobID) {
      try {
        setUpdateSettings(prev => ({ ...prev, isUpdating: true }));

        await dispatch(TrainingActions.updateTrainingJob.request({
          trainingJobID: job.trainingJobID,
          updatedAttributes: {
            modelName: updateSettings.modelName,
            checkpointPath: updateSettings.checkpointPath,
            trainingConfig: {
              is_erosion_enabled: updateSettings.isErosionEnabled,
              is_masking_enabled: updateSettings.isMaskingEnabled,
              use_edge_detection: updateSettings.useEdgeDetection,
            },
          },
        }));

        setUpdateSettings(prev => ({ ...prev, isUpdating: false }));
        onClose();
      } catch (error) {
        console.error('Error updating training job config:', error);
        setUpdateSettings(prev => ({ ...prev, isUpdating: false }));
      }
    }
  };

  return (
    <Modal
      size={"full"}
      isOpen={true} onClose={onClose}>
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header>Update Training Job</Modal.Header>
        <Modal.Body>
          <Center flex={1}>
            <Box p={1} bg="white" borderRadius="10" width="100%">
              <ScrollView>
                <Text mt={2}>Job ID: {job.trainingJobID}</Text>
                <VStack mt={4}>
                  <Text>Model Name:</Text>
                  <Select
                    selectedValue={updateSettings.modelName}
                    onValueChange={(value) => handleSettingsChange('modelName', value)}
                    placeholder="Select Model"
                  >
                    <Select.Item label="resnet-18" value="resnet-18" />
                    <Select.Item label="resnet-50" value="resnet-50" />
                  </Select>
                </VStack>
                <VStack mt={4}>
                  <Text>Checkpoint Path:</Text>
                  <Input
                    placeholder="Enter checkpoint path"
                    value={updateSettings.checkpointPath}
                    onChangeText={(value) => handleSettingsChange('checkpointPath', value)}
                  />
                </VStack>
                <HStack alignItems="center" space={2} mt={4}>
                  <Text>Is Erosion Enabled:</Text>
                  <Switch
                    isChecked={updateSettings.isErosionEnabled}
                    onToggle={() => handleSettingsChange('isErosionEnabled', !updateSettings.isErosionEnabled)}
                  />
                </HStack>
                <HStack alignItems="center" space={2} mt={4}>
                  <Text>Is Masking Enabled:</Text>
                  <Switch
                    isChecked={updateSettings.isMaskingEnabled}
                    onToggle={() => handleSettingsChange('isMaskingEnabled', !updateSettings.isMaskingEnabled)}
                  />
                </HStack>
                <HStack alignItems="center" space={2} mt={4}>
                  <Text>Use Edge Detection:</Text>
                  <Switch
                    isChecked={updateSettings.useEdgeDetection}
                    onToggle={() => handleSettingsChange('useEdgeDetection', !updateSettings.useEdgeDetection)}
                  />
                </HStack>
              </ScrollView>
            </Box>
          </Center>
        </Modal.Body>
        <Modal.Footer>
          <Button.Group space={2}>
            <Button variant="ghost" colorScheme="blueGray" onPress={onClose}>
              Cancel
            </Button>
            <Button onPress={() => onCopyJobId(job.trainingJobID)}>
              Copy Job ID
            </Button>
            <Button onPress={handleSaveUpdateJobConfig} isLoading={updateSettings.isUpdating}>
              Save Config
            </Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default UpdateJobModal;