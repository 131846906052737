import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../redux/reducers";
import * as OrgActions from '../../../redux/actions/orgAction';
import { View, RefreshControl, ListRenderItem, TouchableOpacity } from 'react-native';
import { Box, Text, FlatList } from 'native-base';
import { containerStyle } from 'src/styles/containerStyle';
import { ICenter } from 'src/models/Center';
import CenterWithStatsListItem from '../../listItem/CenterWithStatsListItem';
import ProgressBar from '../../ProgressBar';

export default function CentersWithStatsListComponent({ navigation }) {
  
  const [refreshing, setRefreshing] = React.useState(false);
  
  const isFetchingCenters = useSelector((store: IStore) => store.org?.centersWithStats?.isFetching) || false;
  const centersWithStats = useSelector((store: IStore) => store.org?.centersWithStats?.centers) || [];
  const orgId = useSelector((store: IStore) => store.user.userProfile.response?.org.orgId);
  
  const districtFilter = useSelector((store: IStore) => store.app.districtFilter) || "all";
  const stateFilter = useSelector((store: IStore) => store.app.stateFilter) || "all";
  const grainIdFilter = useSelector((store: IStore) => store.app.grainIdFilter) || "all";
  const dateRange = useSelector((store: IStore) => store.app.dateRange);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchCenterWithStats();
  }, [orgId, districtFilter, stateFilter, grainIdFilter, dateRange]);

  const fetchCenterWithStats = async () => {
    if (orgId) {
      dispatch(OrgActions.getCentersWithStats.request({
        orgId: orgId,
        state: stateFilter || undefined,
        district: districtFilter,
        grainId: grainIdFilter || undefined,
        startDate: dateRange?.from || undefined,
        endDate: dateRange?.to || undefined,
        stats: true
      }));
    }
  };

  const handleCenterSelect = (centerId: string) => {
    dispatch(OrgActions.setCenterId(centerId));
    navigation.navigate('History'); // Navigate to the History screen
  };
  const renderItem: ListRenderItem<ICenter> = ({ item }) => (
    <TouchableOpacity onPress={() => handleCenterSelect(item.centerId)}>
      <CenterWithStatsListItem item={item} />
    </TouchableOpacity>
  );

  return (
    <View style={containerStyle.listContainer}>
      <FlatList
        data={centersWithStats}
        renderItem={renderItem}
        keyExtractor={item => item.centerId}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={fetchCenterWithStats}
          />
        }
        ListEmptyComponent={
          isFetchingCenters ? <View style={{
            flex: 1,
            padding: 48,
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <ProgressBar
              progressText="Loading..."
              isVisible={true}
              withoutOverlay={true} />
          </View> : <Box style={{
            flex: 1,
            padding: 48,
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Text>No centers found for the selected filters</Text>
          </Box>
        }
      />
    </View>
  );
}
