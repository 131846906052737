import React from 'react';
import { HStack, Text, IconButton, Icon, Pressable } from 'native-base';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
interface DatasetListItemProps {
  item: any;
  rowNumber: number;
  onDatasetPress: (item: any) => void;
  onLabelDataset: (datasetID: string) => void;
  onUploadImages: (datasetID: string) => void;
  onDeleteDataset: ((datasetID: string) => void) | null;
  getGrainName: (grainId: string) => string;
}
const DatasetListItem: React.FC<DatasetListItemProps> = ({
  item,
  rowNumber,
  onDatasetPress,
  onLabelDataset,
  onUploadImages,
  onDeleteDataset,
  getGrainName,
}) => {
  if (!item) return null;
  const formatUpdatedAt = (timestamp: number) => {
    if (!timestamp) return 'N/A';
    const dateObj = new Date(timestamp * 1000);
    const istString = dateObj.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
    const istDate = new Date(istString);
    const dd = String(istDate.getDate()).padStart(2, '0');
    const mm = String(istDate.getMonth() + 1).padStart(2, '0');
    const yyyy = istDate.getFullYear();
    return `${dd}/${mm}/${yyyy}`;
  };
  return (
    <Pressable onPress={() => onDatasetPress(item)} width="100%">
      <HStack
        space={2}
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Text flex={0.5} textAlign="center">
          {rowNumber}
        </Text>
        <Text flex={2} fontWeight="bold">
          {item.datasetID}
        </Text>
        <Text flex={1}>{getGrainName(item.grainId)}</Text>
        <Text flex={1}>{item.username}</Text>
        <Text flex={1} textAlign="center">
          {item.status}
        </Text>
        <Text flex={1} textAlign="center">
          {formatUpdatedAt(item.updatedAt)}
        </Text>
        {/* Replace with "label-multiple" icon */}
        <IconButton
          icon={<Icon as={MaterialCommunityIcons} name="label-multiple" size="sm" />}
          onPress={() => onLabelDataset(item.datasetID)}
          aria-label="Label Dataset"
        />

        {/* Replace with "upload" icon */}
        <IconButton
          icon={<Icon as={MaterialCommunityIcons} name="upload" size="sm" />}
          onPress={() => onUploadImages(item.datasetID)}
          aria-label="Upload Images"
        />

        {/* Replace with "delete" icon */}
        {onDeleteDataset && (
          <IconButton
            icon={<Icon as={MaterialCommunityIcons} name="delete" size="sm" color="red.500" />}
            onPress={() => onDeleteDataset(item.datasetID)}
            aria-label="Delete Dataset"
          />
        )}
      </HStack>
    </Pressable>
  );
};

export default DatasetListItem;