import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Center, Box, VStack, Text, Select, Switch, HStack, Button, useToast } from 'native-base';
import * as TrainingActions from 'src/redux/actions/trainingAction';
import DatasetSelector from 'src/components/dataSelector';
import { useSelector } from 'react-redux';
import { IStore } from 'src/redux/reducers';

interface NewJobModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedGrainId: string;
  dispatch: any;
  navigation: any;
}

const TrainingJobCreationModal: React.FC<NewJobModalProps> = ({
  isOpen,
  onClose,
  selectedGrainId,
  dispatch,
  navigation,
}) => {
  const toast = useToast();
  const { datasets = [], loading: datasetsLoading } = useSelector<IStore, { datasets: any[], loading: boolean }>((state) => state.dataset);
  const { loading: trainingLoading, error: trainingError } = useSelector<IStore, any>((state) => state.training);
  
  const [creationSettings, setCreationSettings] = useState({
    modelName: 'resnet-18',
    isErosionEnabled: false,
    isMaskingEnabled: false,
    useEdgeDetection: false,
  });
  
  const [selectedDatasetIds, setSelectedDatasetIds] = useState<string[]>([]);
  const [filteredDatasets, setFilteredDatasets] = useState<any[]>([]);

  useEffect(() => {
    const filtered = selectedGrainId === "all" 
    ? datasets 
    : datasets.filter(dataset => 
        dataset.grainId === selectedGrainId && dataset.status === 'COMPLETED'
      );
    filtered.sort((a, b) => b.createdAt - a.createdAt);
    setFilteredDatasets(filtered);
  }, [datasets, selectedGrainId]);

  useEffect(() => {
    if (trainingError) {
      toast.show({
        title: "Failed to Create Job",
        description: trainingError,
        status: "error",
        placement: "top",
        duration: 5000,
      });
      dispatch(TrainingActions.clearTrainingError());
    }
  }, [trainingError, toast, dispatch]);

  const handleSettingsChange = (field: string, value: any) => {
    setCreationSettings(prev => ({ ...prev, [field]: value }));
  };

  const handleDatasetSelect = useCallback((datasetId: string) => {
    setSelectedDatasetIds(prev => 
      prev.includes(datasetId) 
        ? prev.filter(id => id !== datasetId) 
        : [...prev, datasetId]
    );
  }, []);

  const handleSelectAll = useCallback(() => {
    setSelectedDatasetIds(prev => 
      prev.length === filteredDatasets.length 
        ? [] 
        : filteredDatasets.map(dataset => dataset.datasetID)
    );
  }, [filteredDatasets]);

  const handleClose = useCallback(() => {
    setCreationSettings({
      modelName: 'resnet-18',
      isErosionEnabled: false,
      isMaskingEnabled: false,
      useEdgeDetection: false,
    });
    setSelectedDatasetIds([]);
    onClose();
  }, [onClose]);

  const handleCreateTrainingJob = async () => {
    try {
      await dispatch(
        TrainingActions.createTrainingJob.request({
          datasetIds: selectedDatasetIds,
          grainId: selectedGrainId,
          modelName: creationSettings.modelName,
          trainingConfig: {
            is_erosion_enabled: creationSettings.isErosionEnabled,
            is_masking_enabled: creationSettings.isMaskingEnabled,
            use_edge_detection: creationSettings.useEdgeDetection,
          },
        })
      );
    } catch (error) {
      console.error('Error dispatching training job:', error);
    }
  };

  return (
    <Modal
      size={"full"}
      isOpen={isOpen} 
      onClose={handleClose}>
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header>Create New Training Job</Modal.Header>
        <Modal.Body>
          <Center flex={1}>
            <Box p={1} width="100%">
              <VStack space={4}>
                {filteredDatasets.length === 0 ? (
                  <VStack space={4} alignItems="center">
                    <Text>No labeled datasets available. Please label the existing datasets.</Text>
                    <Button onPress={() => navigation.navigate('DatasetListingPage')}>
                      Go to Dataset Listing Page
                    </Button>
                  </VStack>
                ) : (
                  <>
                    <DatasetSelector
                      filteredDatasets={filteredDatasets}
                      selectedDatasetIds={selectedDatasetIds}
                      onDatasetSelect={handleDatasetSelect}
                      onSelectAll={handleSelectAll}
                    />

                    <VStack>
                      <Text>Model Name:</Text>
                      <Select
                        selectedValue={creationSettings.modelName}
                        onValueChange={(value) => handleSettingsChange('modelName', value)}
                        placeholder="Select Model"
                      >
                        <Select.Item label="resnet-18" value="resnet-18" />
                        <Select.Item label="resnet-50" value="resnet-50" />
                      </Select>
                    </VStack>

                    <HStack alignItems="center" space={2}>
                      <Text>Is Erosion Enabled:</Text>
                      <Switch
                        isChecked={creationSettings.isErosionEnabled}
                        onToggle={() => handleSettingsChange('isErosionEnabled', !creationSettings.isErosionEnabled)}
                      />
                    </HStack>

                    <HStack alignItems="center" space={2}>
                      <Text>Is Masking Enabled:</Text>
                      <Switch
                        isChecked={creationSettings.isMaskingEnabled}
                        onToggle={() => handleSettingsChange('isMaskingEnabled', !creationSettings.isMaskingEnabled)}
                      />
                    </HStack>

                    <HStack alignItems="center" space={2}>
                      <Text>Use Edge Detection:</Text>
                      <Switch
                        isChecked={creationSettings.useEdgeDetection}
                        onToggle={() => handleSettingsChange('useEdgeDetection', !creationSettings.useEdgeDetection)}
                      />
                    </HStack>
                  </>
                )}
              </VStack>
            </Box>
          </Center>
        </Modal.Body>
        <Modal.Footer>
          <Button.Group space={2}>
            <Button variant="ghost" colorScheme="blueGray" onPress={handleClose}>
              Cancel
            </Button>
            <Button
              onPress={handleCreateTrainingJob}
              isLoading={trainingLoading}
              isDisabled={selectedDatasetIds.length === 0 || trainingLoading}
            >
              Create Training Job
            </Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default TrainingJobCreationModal;