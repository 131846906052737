import React, { useEffect } from 'react';
import { IStore } from "../../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import * as UploadAction from '../../../redux/actions/dataset';
import * as GrainTypeAction from 'src/redux/actions/grainTypeAction';
import * as JobActions from '../../../redux/actions/jobAction';
import { RefreshControl } from 'react-native';
import { JobStatus, IUserJobListItem, ISearchQuery, IDateRange, IJob } from '../../../models';
import { Box, FlatList, Button } from 'native-base';
import { listStyle } from 'src/styles/listStyle';
import { downloadScannedImageFromId } from 'src/services/download';
import UserJobHistoryItem from 'src/components/listItem/UserJobHistoryItem';

interface IUserJobHistoryListProps {
  navigation: any;
  pageType: string; // tabs or single
}

export default function UserJobHistoryList(props: IUserJobHistoryListProps) {

  const [listData, setListData] = React.useState<IUserJobListItem[]>([]);

  const [refreshing, setRefreshing] = React.useState(false);
  const [selectedJobId, setSelectedJobId] = React.useState("");
  const currentStack = useSelector((store: IStore) => store.app.currentStack);
  const userJobs = useSelector((store: IStore) => store.job.userJobs.jobs);
  const username = useSelector((store: IStore) => store.user.userProfile.response?.username);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchUserJobs();
    pollUserJobs();
  }, [username]);

  useEffect(() => {
    let listItems: IUserJobListItem[] = []
    userJobs?.Items
      .filter((item) => isDisplayableJob(item.jobStatus))
      .forEach((item) => {
        listItems.push({
          "jobId": item.jobId,
          "jobStatus": item.jobStatus,
          "grainId": item.grainId,
          "createdAt": item.createdAt,
          "updatedAt": item.updatedAt,
          "variety": item.info !== undefined
            && "variety" in item.info
            ? item.info["variety"] as string : "Default",
          "mlLabelingStatus": item.mlLabelingStatus
        })
      })
    setListData(listItems)
  }, [userJobs]);

  const pollUserJobs = async () => {
    if (username !== undefined) {
      dispatch(JobActions.pollUserJobs.request({
        'username': username
      }))
    }
  }

  const fetchUserJobs = async () => {
    if (username !== undefined) {
      dispatch(JobActions.userJobs.request({
        'username': username
      }))
    }
  }

  const isDisplayableJob = (status: JobStatus) => {
    const statusTobeDisplayed = currentStack === 'mlAdmin' ? [JobStatus.JOB_COMPLETED] :
      [
        JobStatus.JOB_IMAGE_UPLOADED,
        JobStatus.JOB_IN_PROGRESS,
        JobStatus.JOB_COMPLETED,
        JobStatus.JOB_FAILED
      ]

    return statusTobeDisplayed.includes(status)
  }

  const grainTypes = useSelector((store: IStore) => store.user.userProfile.response?.grains);
  const grainIdFilter = useSelector((store: IStore) => store.app.grainIdFilter) || "all";
  const searchQuery: ISearchQuery | undefined = useSelector((store: IStore) => store.app.searchQuery);
  const dateRange: IDateRange | undefined = useSelector((store: IStore) => store.app.dateRange);
  const colorMode = useSelector((store: IStore) => store.app.colorMode);
  useEffect(() => {
    updateJobList();
  }, [grainIdFilter, searchQuery, dateRange, userJobs, grainTypes]);

  useEffect(() => {
    if (dateRange && dateRange.from && dateRange.to) {
      const localFiltered = userJobs?.Items.filter((item: IJob) =>
        item.updatedAt !== undefined && item.updatedAt >= dateRange.from && item.updatedAt <= dateRange.to
      );
      if ((!localFiltered || localFiltered.length === 0) && username) {
        dispatch(JobActions.userJobs.request({
          username,
          from: dateRange.from,
          to: dateRange.to
        }));
      }
    }
  }, [dateRange, userJobs, username]);

  const updateJobList = () => {
    let listItems: IUserJobListItem[] = []
    userJobs?.Items
      .filter((item) => isDisplayableJob(item.jobStatus))
      .filter((item) => filterByGrainId(item.grainId))
      .filter((item: IJob) => filterBySearchQuery(item))
      .filter((item: IJob) => filterByDateRange(item))
      .forEach((item) => {
        listItems.push({
          "jobId": item.jobId,
          "jobStatus": item.jobStatus,
          "grainId": item.grainId,
          "createdAt": item.createdAt,
          "updatedAt": item.updatedAt,
          "variety": item.info !== undefined
            && "variety" in item.info
            ? item.info["variety"] as string : "Default",
          "mlLabelingStatus": item.mlLabelingStatus
        })
      })
    setListData(listItems)
  }

  const filterByGrainId = (id: any) => {
    if (grainIdFilter === undefined
      || grainIdFilter === ""
      || grainIdFilter === "0"
      || grainIdFilter === "all") {
      return true
    }
    return id === grainIdFilter
  }

  const filterBySearchQuery = (item: IJob) => {
    if (searchQuery === undefined || searchQuery?.query === undefined || searchQuery?.query === "") {
      return true
    }

    if (searchQuery.type === 'jobId') {
      return item.jobId.includes(searchQuery.query)
    }
    return false
  }

  const filterByDateRange = (item: IJob) => {
    if (dateRange === undefined || dateRange?.from === undefined || dateRange?.to === undefined) {
      return true
    }

    return item.updatedAt !== undefined && item.updatedAt >= dateRange.from && item.updatedAt <= dateRange.to;
  }
  const deleteJob = (jobId: string) => {
    dispatch(JobActions.deleteJob.request(jobId));
    setListData(prev => prev.filter(job => job.jobId !== jobId));
  };
  const handleItemClick = (item: IUserJobListItem) => {

    const selectedJobStatus = userJobs?.Items.find((job) => job.jobId === item.jobId);

    if (selectedJobStatus !== undefined) {
      dispatch(JobActions.jobStatus.success(selectedJobStatus));
      dispatch(JobActions.setJobId(item.jobId));
      dispatch(JobActions.pollJobStatus.request(item.jobId));

      if (currentStack === 'mlAdmin') {
        dispatch(JobActions.setJobId(`dataset_jobId_${item.jobId}`));
        dispatch(UploadAction.setId(`dataset_jobId_${selectedJobStatus.jobId}`));
        if (selectedJobStatus.grainId) {
          dispatch(GrainTypeAction.setGrainId(selectedJobStatus.grainId));
        }
        dispatch(UploadAction.setIsJob(true));
        dispatch(UploadAction.setIsExistingDataset(false));
        props.navigation.navigate('CombinedLabelingPage');
      } else {
        setSelectedJobId(item.jobId);
        if (props.pageType === 'tabs') {
          props.navigation.jumpTo('Analysis');
        } else {
          props.navigation.navigate('ResultsPage');
        }
      }
    }
  };
  const renderItem = ({ item }:
    { item: IUserJobListItem }
  ) => {
    return (
      <UserJobHistoryItem
        item={item}
        downloadImageCallback={async () => {
          await downloadScannedImageFromId(item.jobId);
        } }
        itemCallback={() => handleItemClick(item)}
        retryJobCallback={() => {
          dispatch(JobActions.queueJob.request({ jobId: item.jobId }));
        } }
        deleteJobCallback={deleteJob}     />
    );
  };

  const renderFooter = () => {
    return (
      <Box style={listStyle.footer}>
        <Button onPress={() => {
          fetchUserJobs()
        }} isLoading={refreshing} >Load more</Button>
      </Box>
    );
  };

  return (
    <Box flex={1} bg={colorMode === 'light' ? 'primary.50' : 'primary.900'}>
      <FlatList
        data={listData.sort((a: any, b: any) => {
          return b.updatedAt - a.updatedAt
        })}
        renderItem={renderItem}
        keyExtractor={item => item.jobId}
        onEndReached={fetchUserJobs}
        ListFooterComponent={renderFooter}
        onEndReachedThreshold={0.3}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={() => {
            fetchUserJobs();
          }} />
        }
      />
    </Box>
  );
};