import React, { useCallback } from 'react';
import { FlatList, HStack, Text, Button, Spinner, Center, VStack } from 'native-base';
import { getEpochDate } from '../../../utils/utils';  

interface Job {
  trainingConfig: any;
  checkpointPath: any;
  updatedAttributes: any;
  trainingJobID: string;
  grainId: string;
  modelName: string;
  status: string;
  createdAt: string;
}

interface TrainingJobsListProps {
  jobs: Job[];
  loading: boolean;
  onUpdateJob: (job: Job) => void;
  onCopyJobId: (jobID: string) => void;
}

const TrainingJobsList: React.FC<TrainingJobsListProps> = ({ 
  jobs, 
  loading, 
  onUpdateJob, 
  onCopyJobId 
}) => {
  const renderTrainingJobItem = useCallback(({ item }: { item: Job }) => (
    <HStack 
      justifyContent="space-between" 
      alignItems="center" 
      mb={2} 
      p={2} 
      borderWidth={1} 
      borderColor="gray.200" 
      borderRadius={5}
    >
      <VStack>
        <Text fontWeight="bold">Job ID: {item.trainingJobID}</Text>
        <Text>Grain Type: {item.grainId}</Text>
        <Text>Model: {item.modelName || 'N/A'}</Text>
        <Text>Created At: {getEpochDate(Number(item.createdAt))}</Text>
      </VStack>
      <HStack space={2}>
        <Button onPress={() => onCopyJobId(item.trainingJobID)}>
          Copy ID
        </Button>
        <Button onPress={() => onUpdateJob(item)}>
          Update
        </Button>
      </HStack>
    </HStack>
  ), [onCopyJobId, onUpdateJob]);

  if (loading) {
    return (
      <Center>
        <Spinner accessibilityLabel="Loading training jobs" />
        <Text mt={2}>Loading training jobs...</Text>
      </Center>
    );
  }

  if (jobs.length === 0) {
    return <Text>No training jobs found.</Text>;
  }

  return (
    <FlatList
      data={[...jobs].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())}
      renderItem={renderTrainingJobItem}
      keyExtractor={(item) => item.trainingJobID}
    />
  );
};

export default TrainingJobsList;